import {reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, nextTick, computed} from 'vue';
import DzzlCardUtil ,{IDzzlCardDataObj} from './dzzlCardUtil';
import DcwtdCard from "@/views/project/order/fy/zl/dcwtd/DcwtdCard.vue";
import QptzdCard from "@/views/project/order/fy/zl/qptzd/QptzdCard.vue";
export default defineComponent({
    name:'DzzlCard',
    title:'单证资料',
    modelType:'card',
    fullscreen: true,
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IDzzlCardDataObj=reactive<IDzzlCardDataObj>({
            utilInst:{} as any,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/dzzl')
            },
            //表单
            form:{},
            otherParams:{
                qpList:[],
                bgzlInfo:{
                    htxxd:[],//合同信息单
                    xdxxd:[],//箱单信息单
                    fpxxd:[],//发票信息单
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DzzlCardUtil(proxy,dataObj,props);
        })
        onMounted(()=>{})
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            //把一些数据备份到form，方便其它地方用，比如查看订舱委托书
            dataObj.form.bgzlBean=res.bgzlBean;
            dataObj.utilInst.initBgzlInfo(res);
            dataObj.otherParams.qpList=res.qpList;

            nextTick(async()=>{
                dataObj.refMap.get('ckxkz').uploadParams.belongMaxId=dataObj.form.bgzlBean.id;
                dataObj.refMap.get('bgd').uploadParams.belongMaxId=dataObj.form.bgzlBean.id;
                dataObj.refMap.get('fxtzs').uploadParams.belongMaxId=dataObj.form.bgzlBean.id;
                dataObj.refMap.get('qgdj').uploadParams.belongMaxId=dataObj.form.bgzlBean.id;
                dataObj.refMap.get('otherAttach').uploadParams.belongMaxId=dataObj.form.bgzlBean.id;
            })
        }
        const setParam=(engineInst:any)=>{
            return {
                bgzlId:engineInst.engineParams.bgzlId,
                cardFrom:engineInst.engineParams.cardFrom
            };
        }
        //---------------------------computed---------------------------
        const buildUploadInitParams=computed(()=>{
            return (params:any) => {
                let uploadParams:any={showFileList:true, uploadType:params.uploadType, type:params.type, title:params.title};
                switch (params.type) {
                    case 5://出口许可证
                    case 6://报关单
                    case 7://放行通知书
                    case 8:uploadParams.disabled=true;break;//清关单据
                    case 9://附件补充
                        uploadParams=Object.assign(uploadParams,{fileSize:80, accept:'.pdf'});
                    break;
                }
                return uploadParams;
            }
        })
        //下载附件
        const handlePreview=(file:any)=>{
            utils.Tools.configBox({
                message:'即将下载该附件，是否继续？',
                sureFn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.Api.downLoad(params)
                }
            });
        }
        //预览订舱委托单
        const preViewDcwtdHandler=async ()=>{
            await utils.UtilPub.openDialog({id:dataObj.form.bgzlBean.hdxxId,proxy:proxy,addOrEdit:'/load',modelComp:DcwtdCard,ownerComp:proxy,
                cardFrom:'DzzlCard',overflow:'hidden',title: '订舱委托单',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //预览启票通知单
        const preViewQptzdHandler=async (id:string)=>{
            await utils.UtilPub.openDialog({id:id,proxy:proxy,addOrEdit:'/load',modelComp:QptzdCard,ownerComp:proxy,
                cardFrom:'DzzlCard',overflow:'hidden',title: '启票通知单',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //查看pdf
        const pdfHandler=async (id:string,type:number)=>{
            switch (type) {
                case 0:await dataObj.utilInst.openPdf(id,0,'合同信息单');break;//新增合同信息单
                case 1:await dataObj.utilInst.openPdf(id,1,'箱单信息单');break;//新增箱单信息单
                case 2:await dataObj.utilInst.openPdf(id,2,'发票信息单');break;//新增发票信息单
            }
        }
        return{
            ...toRefs(dataObj),proxy,beforeOpen,setParam,buildUploadInitParams,handlePreview,preViewDcwtdHandler,preViewQptzdHandler
            ,pdfHandler
        }
    }
});