import PiCard from '../pi/PiCard.vue';
import {reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, onActivated, computed} from 'vue';
import FormalOrderListUtil ,{IFormalOrderListDataObj} from './formalOrderListUtil';
import FormalOrderDetail from "@/views/project/order/order/formalOrder/formalOrderDetail/FormalOrderDetail.vue";
import OrderFlowStep from "@/views/project/order/order/formalOrder/orderFlowStep/OrderFlowStep.vue";


export default defineComponent ({
    name: 'FormalOrderList',
    props: {
        //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IFormalOrderListDataObj=reactive<IFormalOrderListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                gridTitle:'正式订单管理',
                isShowFixCol:false,
                expand:true,
                border: false,
                multiple:props.engineParams?true:false,//由弹出包含订单列表的时候，显示复选框
                queryParam: {
                    from:props.engineParams?props.engineParams.from:'',//传给后台，决定是否显示顶部按钮，弹窗包含列表不显示，通过左边菜单打开列表则显示对应按钮
                    customerId:props.engineParams?props.engineParams.customerId:'',//传给后台，决定是否按客户过滤
                    bz:props.engineParams?props.engineParams.bz:'',//传给后台，决定是否按币种过滤
                    type:props.engineParams?props.engineParams.type:''//传给后台，按用途种过滤（tt支付专用）
                },
                cardFrom:'FormalOrderList',
                modelComp: PiCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/formalOrder/pageData')
            },
            otherParams:{
                count:0,
                activeName:'all',
                scjhh:{//生产计划号
                    visible:false,//弹出框隐藏/显示控制标识
                    orderId:'',//订单id
                    scjhh:''//输入的生产计划号
                }
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new FormalOrderListUtil(proxy,dataObj);
        })
        onMounted(()=>{})
        onActivated(()=>{})
        const formatPageInfo=(options:any)=>{
            return options;
        }

        //设置生产计划号
        const scjhhHandler=()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                dataObj.otherParams.scjhh.orderId=selectRows[0].F_ORDER_ID;
                dataObj.otherParams.scjhh.scjhh=selectRows[0].F_SCJHH;
                dataObj.otherParams.scjhh.visible=true;
            }
        }

        //拆单按钮事件
        const splitOrderHandler=async ()=>{
            let selectRows = dataObj.utilInst.getSelectedRow();
            if(selectRows){
                // if(selectRows[0].F_SPLIT_FLAG==0)
                //     await dataObj.utilInst.doSplitHandler(selectRows);
                // else proxy.$message('不允许执行该操作');
                await dataObj.utilInst.doSplitHandler(selectRows);
            }
        }

        //查看订单分配和维护等详细信息
        const orderDetail=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:FormalOrderDetail,ownerComp:dataObj.pageListRef,
                orderId:row.F_ORDER_ID,
                cardFrom:'FormalOrderListUtil',overflow:'hidden',title: '正式订单详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //查看pi弹出框
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:proxy,cardFrom:'FormalOrderList',
                title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        //查看当前进度
        const flowStepHandler=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:OrderFlowStep,ownerComp:dataObj.pageListRef,
                orderId:row.F_ORDER_ID,
                cardFrom:'OrderFlowStep',overflow:'hidden',title: '订单进度详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //计算尾款
        /**
         * 本来后台已经传来了尾款_f_wk,为什么不直接用，要计算一遍呢？
         * 因为在前台对预付款和尾款都用utils.UtilPub.toAmountFormat的话，那么就相当于预付款和尾款都进行了四舍五入，
         * 这个时候，就可能出现预付款+尾款！=订单金额的情况。比如后台数据返回的金额是如下情况的时候，就有0.0的误差
         * f_amount=1306.50  _f_yfk=901.485000 _f_wk=405.015000
         * 假如对预付款和尾款都进行四舍五入，那么他们相加就不等于f_amount了，就有0.1的误差了，
         * 于是才用下方的总金额-格式化之后的预付款，来取尾款，这个时候，尾款肯定是两位小数，不可能有四舍五入的情况了
         * */
        const calWk=computed(()=>{
            return (params:any) => {
                let yfk=utils.UtilPub.toAmountFormat(params.row._F_YFK,false,2);
                yfk=utils.UtilPub.to_double(yfk)
                let amount=utils.UtilPub.toAmountFormat(params.row.F_TOTAL_AMOUNT,false,2);
                amount=utils.UtilPub.to_double(amount)
                return utils.UtilPub.toAmountFormat(amount-yfk,false,2);
            }
        })
        return{
            ...toRefs(dataObj),formatPageInfo,scjhhHandler,splitOrderHandler,
            orderDetail,piDetail,flowStepHandler,calWk
        }
    }
});