import BaseBean from "@/utils/BaseBean";

export interface ITtPayCardDataObj {
    utilInst:TtPayCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class TtPayCardUtil extends BaseBean{
    public dataObj:ITtPayCardDataObj;

    constructor(proxy:any,dataObj:ITtPayCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.customerAmountData=await this.utils.OrderProviderApi.loadCanPay({params:{from:'ttPay'}});//查询可以用于支付的客户账户
    }
    //提交
    public async doSubmit():Promise<any>{
        this.utils.Tools.configBox({
            message:'提交之后将不能修改单据，确定提交吗?',
            sureFn:async ()=> {
                this.dataObj.form.saveType='submit';
                await this.proxy.engine.saveHandler();
            }
        });
    }
}