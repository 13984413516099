import {reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, computed,nextTick} from 'vue';
import PayDetailUtil ,{IPayDetailDataObj} from './payDetailUtil';
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
import FinanceUtils from "@/views/project/order/finance/financeUtils";

export default defineComponent ({
    name: 'PayDetail',
    props: {
        ownerInst:{type: Object},//父组件
        ownerOtherParams:{type:Object}//父组件传过来的参数
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let ownerInst:any=props.ownerInst;//父组件
        let ownerOtherParams:any=props.ownerOtherParams;//父组件传过来的参数
        let dataObj:IPayDetailDataObj=reactive<IPayDetailDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams:{},
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new PayDetailUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(()=>{
                ownerInst.refMap.get('detail').detailTypeRef.setMaxTbHeight(350)
            })
        })
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        toolBar: [{
                            id: "chooseOrder",
                            title: "选择订单",
                            type: "primary",
                            icon: "iconfont icon-chaxun",
                            clickEvent: 'chooseOrder',
                            param:{}
                        }],
                        gridTitle:ownerOtherParams.gridTitle,
                        readOnly:ownerInst.disabled,
                        canAdd: false,
                        canPage: false,
                        expand: true,
                        border: false,
                        queryParam: {detailType: detailType},
                        modelMethod: utils.OrderProviderApi.buildUrl('/'+ownerOtherParams.urlPre+'/detail?t='+Math.random()*1000)
                    }
                }
            }
        })
        //计算本次能够支付的最大金额
        const payMoney=computed(()=>{
            return (params:any) => {
                return params.row.F_TOTAL_AMOUNT-params.row.F_PAY_AMOUNT;
                // if(params.row.F_ID){//编辑
                //     return params.row.F_TOTAL_AMOUNT-params.row.F_PAY_AMOUNT+params.row._F_AMOUNT;
                // }else{//新增
                //     return params.row.F_TOTAL_AMOUNT-params.row.F_PAY_AMOUNT;
                // }
            }
        })
        //(对于oa和tt支付)如果某行已被解锁了，则加删除提示
        const tableRowClassName = (options:any) => {
            options.row.rowIndex = options.rowIndex;
            if (options.row.F_STATUS === 2) return 'warning-row'
            return ''
        }
        //支付明细如果就是本条记录，那么给子表格行一个颜色吧
        const payDetailClass = (params:any,detailId:string) => {
            if(params.row.detailId==detailId)return 'success-row';
            return ''
        }
        //点击订单编号，其实查看的是pi
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:ownerInst,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:ownerInst,cardFrom:ownerOtherParams.cardFrom,
                title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        //本次付款金额改变事件，重新计算整个明细表格的金额之和
        const amountChange=(curValue:number)=>{
            let data=ownerInst.refMap.get('detail').getDetailData();
            ownerInst.form.amount=FinanceUtils.calTotalPayAmount(data);
        }
        //OA逾期天数绝对值
        const absValue=()=>{

        }
        return{
            ...toRefs(dataObj),utils,cardDetailParam,payMoney,tableRowClassName,payDetailClass,piDetail,amountChange,absValue
        }
    }
});