import BaseBean from "@/utils/BaseBean";

export interface IFormalOrderDetailCardDataObj {
    utilInst:FormalOrderDetailCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class FormalOrderDetailCardUtil extends BaseBean{
    public dataObj:IFormalOrderDetailCardDataObj;

    constructor(proxy:any,dataObj:IFormalOrderDetailCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{

    }
    //页面初始化
    public async initPage():Promise<void>{
        this.dataObj.otherParams.orderId=this.proxy.engine.engineParams.orderId;
    }
}