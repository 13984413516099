import BaseBean from "@/utils/BaseBean";
import FinanceUtils from "@/views/project/order/finance/financeUtils";

export interface IPayDetailDataObj {
    utilInst:PayDetailUtil
    refMap:Map<string,any>
    otherParams: any
}

export default class PayDetailUtil extends BaseBean{
    public dataObj:IPayDetailDataObj

    constructor(proxy:any,dataObj:IPayDetailDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}