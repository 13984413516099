import BaseBean from "@/utils/BaseBean";

export interface IPiCardDataObj {
    utilInst:PiCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    importGuidParams:any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class PiCardUtil extends BaseBean{
    public dataObj:IPiCardDataObj;

    constructor(proxy:any,dataObj:IPiCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        await this.buildPiData({params:{templateId:this.dataObj.form.templateId}});
        this.dataObj.otherParams.piTemplateData=await this.utils.OrderProviderApi.getAllPiTemplate({});
    }
    //选择以前的PI，把对应pi的数据填充过来，所以这里要查询历史PI，构建下拉
    public async buildPiData(params:any):Promise<void>{
        //构建网点下拉数据
        this.dataObj.otherParams.piData=await this.utils.OrderProviderApi.getAllPi(params);
        //只保留提交过的PI
        this.dataObj.otherParams.piData=this.dataObj.otherParams.piData.filter((item:any)=>{
            if(item.item.tjUserId!=item.item.loginUserId)item.disabled=true;
            return item.item.tjUserId?true:false;
        })
    }
    //检查保存的数据是否合法
    public checkSaveData():boolean{
        if(this.dataObj.form.templateId==this.utils.Const.pi_template_russia){//当pi模板为俄罗斯模板的时候
            if(!this.dataObj.form.hthjsxq || !this.dataObj.form.fkfs){
                this.proxy.$message('合同号及生效期、付款方式不能为空');
                return false;
            }
        }
        let orderData=this.dataObj.refMap.get('order').otherParams.orderData;
        if(!orderData.otherInfo.percent){
            this.utils.Tools.warning({message:'首付比例不能为0'});
            this.dataObj.otherParams.activeName='orderInfo';
            return false;
        }

        if(orderData.otherInfo.showUs=='1' && !orderData.otherInfo.rate){//美元支付需要判断利率是否为0
            this.utils.Tools.warning({message:'利率不能为0'});
            this.dataObj.otherParams.activeName='orderInfo';
            return false;
        }

        //提醒人名币为0的项
        let existZeroPrice=false;
        orderData.cfgData.forEach((item1:any)=>{
            item1.itemChildren.forEach((item2:any)=>{
                if(!item2.header.price){
                    existZeroPrice=true;
                    this.utils.Tools.warning({message:'【'+item2.header.cxName+'】 存在单价为0项'});
                    return;
                }
            })
        })
        if(existZeroPrice){
            this.dataObj.otherParams.activeName='orderInfo';
            return false;
        }
        this.dataObj.form.orderData=orderData;
        if(this.dataObj.form.piId)this.dataObj.form.piId=this.dataObj.form.piId.value;//这个是对象，所以存的时候要取值来存储。
        return true;
    }

    //转正或者作废订单，向后台发起请求
    public doZz_invalid(type:number):any{
        let title='确定转正式订单?';
        if(type==1)title='确定作废订单?';
        this.utils.Tools.configBox({
            title:title,
            message:'意向订单号:'+this.dataObj.form.orderCode+'',
            sureFn:async ()=> {
                let res;
                switch (type) {
                    case 0://转正
                        res=await this.utils.OrderProviderApi.toFormalOrder({params:{id:this.dataObj.form.orderId}});break;
                    case 1://作废
                        res=await this.utils.OrderProviderApi.invalidOrder({params:{id:this.dataObj.form.orderId}});break;
                }
                if(res.result){
                    this.utils.Tools.success();
                    this.proxy.engine.engineParams.ownerComp.queryHandler(true)
                    this.proxy.dialogInst.dialogVisible=false;//关闭弹出框卡片
                }
            }
        });
    }
}