import BaseBean from "@/utils/BaseBean";
import SplitOrder from "@/views/project/order/order/formalOrder/splitOrder/SplitOrder.vue";

export interface IFormalOrderListDataObj {
    utilInst:FormalOrderListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class FormalOrderListUtil extends BaseBean{
    public dataObj:IFormalOrderListDataObj

    constructor(proxy:any,dataObj:IFormalOrderListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //得到的选择行
    public getSelectedRow():any{
        let selectRows = this.dataObj.pageListRef.getTbInst().getSelection();
        if (selectRows.length == 0) {
            this.proxy.$message(this.proxy.$t('请选择要操作的行'));
            return;
        }
        return selectRows;
    }
    //处理拆单事件
    public async doSplitHandler(selectRows:any):Promise<any>{
        this.utils.Tools.configBox({
            message:'是否需要拆分订单',
            confirmButtonText:'是,进入操作页面',
            cancelButtonText:'否,无需拆分',
            distinguishCancelAndClose:true,
            showCancelMsg:false,
            sureFn:async ()=> {
                await this.utils.UtilPub.openDialog({id:'',proxy:this.proxy,addOrEdit:'add',modelComp:SplitOrder,ownerComp:this.dataObj.pageListRef,
                    orderId:selectRows[0].F_ORDER_ID,
                    cardFrom:'FormalOrderSplit',overflow:'hidden',title: '拆单',contentHeight:'',showFooterBtn:false,fullscreen:true})
            },
            cancelFn:async ()=> {
                let res=await this.utils.OrderProviderApi.notSplit({params:{id:selectRows[0].F_ORDER_ID}});
                if(res.result){
                    this.utils.Tools.success();
                    this.proxy.pageListRef.queryHandler();
                }
            },
            closeFn:async ()=>{
                this.utils.Tools.ctx.$message({type: 'info',message: this.utils.Tools.ctx.$t('tools.cancelOperate')});
            }
        });
    }

    //确定生产计划号事件
    public async doScjhh():Promise<any>{
        let res=await this.utils.OrderProviderApi.scjhh({params: this.dataObj.otherParams.scjhh});
        if(res.result){
            this.utils.Tools.success();
            this.proxy.pageListRef.queryHandler();
            this.dataObj.otherParams.scjhh.visible=false;
        }
    }

}