import {reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, nextTick, computed} from 'vue';
import OrderFlowStepCardUtil ,{IOrderFlowStepCardDataObj} from './orderFlowStepCardUtil';

export default defineComponent({
    name:'FormalOrderDetail',
    title:'订单进度详情',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IOrderFlowStepCardDataObj=reactive<IOrderFlowStepCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: false,
                modelPath: utils.OrderProviderApi.buildUrl('/formalOrder')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {},
            otherParams:{
                orderId:'',//订单id
                flowInfo:{//流程信息对象
                    orderBean:{//订单bean，展示详情用
                        code:''//在后台请求返回之前，页面可能已经在解析了，防止页面报错，初始化一下
                    },
                    flowContent:[],//订单流程对象
                    mapStep:new Map(),//key=步骤，value=该步骤的对象信息
                }

            }
        })
        onBeforeMount(()=>{
            /**
             * 由于页面用到了dataObj.otherParams.flowInfo.mapStep.get(步骤).time
             * 这个时候get(步骤)可能还为空，于是我们就要往里面先放入步骤初始化一下
             * */
            let steps=['下订单','拟定PI','转正式订单','完成支付','订单结算','启票','确认发货通知','维护报关资料','清关'];
            steps.forEach((item:any)=>{
                dataObj.otherParams.flowInfo.mapStep.set(item,'');
            })
            dataObj.utilInst=new OrderFlowStepCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })

        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            await dataObj.utilInst.initPage();
            dataObj.otherParams.flowInfo.flowContent=JSON.parse(res.orderLogBean.flowContent);
            //把步骤转为map结构，方便页面通过步骤拿到步骤的详细信息
            dataObj.otherParams.flowInfo.flowContent.forEach((item:any)=>{
                dataObj.otherParams.flowInfo.mapStep.set(item.step,item);
            })
            //订单对象，展示订单详情用
            dataObj.otherParams.flowInfo.orderBean=res.orderBean;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        const setParam=(engineInst:any)=>{
            //从正式订单列表传过来的参数，准备传给后台，后台根据这些参数查询之后返回到beforeOpen那里
            return {orderId:engineInst.engineParams.orderId,cardFrom:engineInst.engineParams.cardFrom};
        }

        return{
            ...toRefs(dataObj),proxy,beforeOpen,beforeSaveHandler,setParam
        }
    },
    components:{
    }
});