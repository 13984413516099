import BaseBean from "@/utils/BaseBean";
import {nextTick} from 'vue';
export interface ISplitOrderCardDataObj {
    utilInst:SplitOrderCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class SplitOrderCardUtil extends BaseBean{
    public dataObj:ISplitOrderCardDataObj;

    constructor(proxy:any,dataObj:ISplitOrderCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }

    //查询订单信息
    public async initPage(id:string):Promise<any>{
        let res=await this.utils.OrderProviderApi.orderInfo({params:{id:id}});
        if(res.result){
            if(res.flag){//如果已经保存过了，那么就用数据库里面已经保存过的内容
                this.dataObj.otherParams.order=JSON.parse(res.splitOrderBean.content);
                this.dataObj.otherParams.order.id=res.orderBean.id;
                //有可能tab还未生成出来，所以把下方的内容放到nextTick中（为edittable赋值）
                nextTick(()=>{
                    this.dataObj.otherParams.order.splitInfo.splitDetail.data.forEach((item:any)=>{
                        this.dataObj.refMap.get(item.name).getTbInst().setTbData(item.tbData);
                    })
                })
            }else{//没有保存过，那么根据后台返回内容构建订单基础信息和客户收货地址
                this.dataObj.otherParams.order.orderBaseInfo={
                    code:res.customerBean.code,
                    name:res.customerBean.name,
                    countryName:res.customerBean.countryName,
                    shrName:res.customerDeliveryBean.name,
                    contact:res.customerDeliveryBean.contact,
                    xhgk:res.customerDeliveryBean.xhgk,
                    address:res.customerDeliveryBean.address,
                    desc:res.orderBean.desc
                }
                this.dataObj.otherParams.order.id=res.orderBean.id;//订单ID
                this.dataObj.otherParams.order.code=res.orderBean.code.substr(2);//订单编码
                this.dataObj.otherParams.order.splitInfo.formatOrderData=res.formatOrderData;//后台构建的订单信息
            }
            //按车型和车型颜色分别存一份到map中、构造配置下拉数据集
            this.dataObj.otherParams.order.splitInfo.orderCombo=[];
            this.dataObj.otherParams.order.splitInfo.cxColorMap=new Map();
            this.dataObj.otherParams.order.splitInfo.cxMap=new Map();

            this.dataObj.otherParams.order.splitInfo.formatOrderData.forEach((item:any)=>{
                this.dataObj.otherParams.order.splitInfo.cxColorMap.set(item.pzId,item);
                this.dataObj.otherParams.order.splitInfo.orderCombo.push({value:item.pzId,label:item.pzName});
                if(this.dataObj.otherParams.order.splitInfo.cxMap.has(item.cxId)){
                    this.dataObj.otherParams.order.splitInfo.cxMap.get(item.cxId).push(item);
                }else{
                    this.dataObj.otherParams.order.splitInfo.cxMap.set(item.cxId,[item]);
                }
            });
        }
        if(this.proxy.childOrderCode){//传入子订单号，表示只显示该子订单号，其它不显示，比如发运列表打开查看详情的时候就会传入
            this.dataObj.otherParams.order.splitInfo.splitDetail.activeName=this.proxy.childOrderCode.split('-')[1]
            this.dataObj.otherParams.order.splitInfo.splitDetail.data=this.dataObj.otherParams.order.splitInfo.splitDetail.data.filter((item:any)=>item.title==this.proxy.childOrderCode)
        }
    }

    //生成拆单明细tab
    public async genSplitOrderTabs(type:number):Promise<any>{
        //先清空edittable的数据，直接清空data数组是不会把edittable的数据给清除掉的，所以才手动清除
        let data=this.dataObj.otherParams.order.splitInfo.splitDetail.data;
        for(let i=0;i<data.length;i++){
            await this.dataObj.refMap.get(data[i].name).clearDetailData();//用foreach会抛异常，因为会异步执行下方清空操作
        }
        this.dataObj.otherParams.order.splitInfo.splitDetail.data=[];//清空tab
        //根据拆单方式，先设置拆单单数
        let cxMapValues:any=[];
        switch (type) {
            case 0:this.dataObj.otherParams.order.num=this.dataObj.otherParams.order.splitInfo.cxColorMap.size;//车型颜色拆单
                break;
            case 1://车型拆单
                this.dataObj.otherParams.order.num=this.dataObj.otherParams.order.splitInfo.cxMap.size;
                //把cxMap的值处理成为数组，方便下方取出来为小单明细表格赋值
                for(let value of this.dataObj.otherParams.order.splitInfo.cxMap.values()){
                    cxMapValues.push(value);
                }
                break;
            case 2:break;//手动拆单
        }
        //生成拆单tab及tab内容
        for(let i=0;i<this.dataObj.otherParams.order.num;i++){
            let title=this.dataObj.otherParams.order.code+'-'+this.dataObj.otherParams.colName[i];//tab的title
            let tbData:any=[];
            switch (type) {
                case 0://按车型颜色拆单，其实formatOrderData中每行就是一个小单内容，也可以取cxColorMap中的记录，只不过不能通过下标取
                    let row=this.dataObj.otherParams.order.splitInfo.formatOrderData[i];
                    row.splitNum=row.num;//tab的拆单数量是splitNum，而每行的数量是num，所以需要手动设置以下
                    tbData.push(row);
                    break;
                case 1://按车型拆单
                    for(let j=0;j<cxMapValues[i].length;j++){
                        cxMapValues[i][j].splitNum=cxMapValues[i][j].num;//tab的拆单数量是splitNum，而每行的数量是num，所以需要手动设置以下
                        tbData.push(cxMapValues[i][j]);
                    }
                    break;
            }
            this.dataObj.otherParams.order.splitInfo.splitDetail.data.push({title:title,tbData:tbData,name:this.dataObj.otherParams.colName[i]});
            this.dataObj.otherParams.order.splitInfo.splitDetail.activeName='A';
            //有可能tab还未生成出来，所以把下方的内容放到nextTick中
            nextTick(()=>this.dataObj.refMap.get(this.dataObj.otherParams.colName[i]).getTbInst().setTbData(tbData))
        }
    }

    //格式化车型公告号配置明细属性
    public formatProperty(property:number):string{
        let result='';
        switch (property) {
            case 0:result='CBU';break;
            case 1:result='CKD';break;
            case 2:result='SKD';break;
        }
        return result;
    }
    //导出
    public exportExcel(type:number):void{
        if(type==1){
            let labels=['品牌','车型','车型公告号','成品码','属性','颜色','数量','单价','币种']
            let data:any=[];
            let total:number=0;
            this.dataObj.otherParams.order.splitInfo.formatOrderData.forEach((item:any)=>{
                let price=item.jsType==0?item.price:item.priceUsd;
                let jsType=item.jsType==0?'人民币':'美元';
                item.jsType==0?total+=item.totalRmb:total+=item.totalUsd;
                data.push([item.brandName,item.cxName,item.cxggh,item.cpm,this.formatProperty(item.property),item.color,item.num,price,jsType]);
            })
            data.push(['总价',total,'','','','','','','']);
            this.proxy.excelUtils(labels, data, '订单'+this.dataObj.otherParams.order.code+'明细');
        }else{
            let labels=['子订单号','品牌','车型','车型公告号','成品码','颜色','数量','单价','币种','属性','收货人名称','联系电话','收货地址']
            let data:any=[];
            let cols:Array<string>=[];
            this.dataObj.otherParams.order.splitInfo.splitDetail.data.forEach((item1:any,index:number)=>{
                cols[index]=item1.name;
                item1.tbData.forEach((item2:any)=>{
                    data.push([item1.title,item2.brandName,item2.cxName,item2.cxggh,item2.cpm,item2.color,item2.splitNum,item2._price,item2.jsType,item2.property,item2.name,item2.contact,item2.address])
                })
            })
            let _title=cols[0];
            if(cols.length>1)_title=_title+'-'+cols[cols.length-1];
            this.proxy.excelUtils(labels, data, '订单'+this.dataObj.otherParams.order.code+'['+_title+']明细');
        }
    }
    //保存检查数据是否合法
    public checkSave():boolean{
        let result:boolean=true;
        let pzMapSave=new Map();//key-pzId,value-该配置id的总数量
        //小单tab
        let tabData=this.dataObj.otherParams.order.splitInfo.splitDetail.data;
        //检查是否有小单没有内容
        tabData.forEach((item:any)=>{
            if(item.tbData.length==0){
                this.proxy.$message({type:'error',message:item.title+'不能为空'});
                result=false;
            }else{//把所有小单tab的内容配置汇总
                item.tbData.forEach((item2:any)=>{
                    let pzId=item2.pzId;
                    let splitNum=item2.splitNum;
                    if(pzMapSave.has(pzId)){
                        pzMapSave.set(pzId,pzMapSave.get(pzId)+splitNum);
                    }else{
                        pzMapSave.set(pzId,splitNum);
                    }
                })
            }
        })
        if(!result)return result;
        //检查汇总的配置是否和订单的配置数量相等
        let allPzData=this.dataObj.otherParams.order.splitInfo.cxColorMap;
        for(let[key,value] of allPzData){
            if(!pzMapSave.has(key)){//保存的map配置里面没有包含该配置
                this.proxy.$message({type:'error',message:value.pzName+'没有拆单',plain:true});
                result=false;
                break;
            }else if(value.num!=pzMapSave.get(key)){//保存的map配置里面包含了该配置，但是配置数量不等
                this.proxy.$message({type:'error',message:value.pzName+'订单数量和拆单总数量不相等',plain:true});
                result=false;
                break;
            }
        }
        return result;
    }
}