import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-07913ecb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "TtPay" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_CardForm = _resolveComponent("CardForm")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_PayDetail = _resolveComponent("PayDetail")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: _ctx.form,
      rules: _ctx.rules,
      "label-width": "100px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CardForm, {
          ownerInst: _ctx.proxy,
          formItems: _ctx.compParams.formItems,
          form: _ctx.form,
          formRef: _ctx.formRef,
          rules: _ctx.rules,
          disabled: _ctx.disabled,
          refMap: _ctx.refMap,
          colNum: 1
        }, {
          extendSlot: _withCtx(() => []),
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "客户",
                      prop: "customerAmountId"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          ref: el=>_ctx.refMap.set('customerAmountId',el),
                          modelValue: _ctx.form.customerAmountId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.customerAmountId) = $event)),
                          "value-key": "value",
                          clearable: "",
                          placeholder: "请选择客户",
                          style: {"width":"100%"},
                          disabled: _ctx.disabled,
                          onChange: _cache[1] || (_cache[1] = (val)=>{_ctx.selectOnChange(val,'customerAmountId')})
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'customerAmountId'}), (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item,
                                disabled: item.disabled
                              }, null, 8, ["label", "value", "disabled"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.form.customerAmountId)
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 0,
                      span: 12
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: "币种",
                          prop: "bz"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.form.bz==0?'人民币':'美元') + _toDisplayString(_ctx.form.balance), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["ownerInst", "formItems", "form", "formRef", "rules", "disabled", "refMap"])
      ]),
      _: 1
    }, 8, ["model", "rules"]),
    _createVNode(_component_PayDetail, {
      ownerInst: this,
      ownerOtherParams: _ctx.otherParams.childParams
    }, null, 8, ["ownerOtherParams"])
  ]))
}