import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ffe7edc2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "FormalOrderList"
}
const _hoisted_2 = { style: {"font-size":"14px","font-weight":"bold","color":"#2c3e50"} }
const _hoisted_3 = {
  key: 0,
  style: {"font-size":"16px","font-weight":"bold"}
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { style: {"font-size":"16px","font-weight":"bold","color":"#c0392b"} }
const _hoisted_6 = { style: {"font-size":"16px","font-weight":"bold","color":"#c0392b"} }
const _hoisted_7 = { style: {"padding":"20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_ListEngine = _resolveComponent("ListEngine")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo,
      gridLoaded: _ctx.gridLoaded,
      onScjhhHandler: _ctx.scjhhHandler,
      onSplitOrderHandler: _ctx.splitOrderHandler
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("订单号：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[0] || (_cache[0] = e => _ctx.pageList.queryParam.orderCode = _ctx.valid(e)),
                  placeholder: "请输入意向订单号",
                  modelValue: _ctx.pageList.queryParam.orderCode,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.orderCode) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("PI编码：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[2] || (_cache[2] = e => _ctx.pageList.queryParam.code = _ctx.valid(e)),
                  placeholder: "请输入PI编码",
                  modelValue: _ctx.pageList.queryParam.code,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageList.queryParam.code) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("客户名称：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[4] || (_cache[4] = e => _ctx.pageList.queryParam.customerName = _ctx.valid(e)),
                  placeholder: "请输入客户名称",
                  modelValue: _ctx.pageList.queryParam.customerName,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageList.queryParam.customerName) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("客户国家：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[6] || (_cache[6] = e => _ctx.pageList.queryParam.customerCountry = _ctx.valid(e)),
                  placeholder: "请输入客户国家",
                  modelValue: _ctx.pageList.queryParam.customerCountry,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.pageList.queryParam.customerCountry) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("是否拆单：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.pageList.queryParam.splitFlag,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.pageList.queryParam.splitFlag) = $event)),
                  placeholder: "请选择是否拆单",
                  style: {"width":"100%"},
                  clearable: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_option, {
                      label: "未操作",
                      value: "0"
                    }),
                    _createVNode(_component_el_option, {
                      label: "否",
                      value: "1"
                    }),
                    _createVNode(_component_el_option, {
                      label: "是",
                      value: "2"
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("添加日期：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 9,
              style: {"padding-right":"20px"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_date_picker, {
                  modelValue: _ctx.pageList.queryParam.addTime,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.pageList.queryParam.addTime) = $event)),
                  type: "daterange",
                  shortcuts: _ctx.utils.Tools.defDateRange()
                }, null, 8, ["modelValue", "shortcuts"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          type: "expand",
          fixed: ""
        }, {
          default: _withCtx((props) => [
            _createVNode(_component_el_table, {
              data: props.row.F_PAY_CONTENT?JSON.parse(props.row.F_PAY_CONTENT):[],
              border: "",
              "header-row-style": {'font-size':'12px','color':'#16a085'}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_table_column, {
                  property: "payType",
                  label: "支付类型"
                }, {
                  default: _withCtx((scope) => [
                    (scope.row.payType==0)
                      ? (_openBlock(), _createBlock(_component_el_tag, {
                          key: 0,
                          type: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("TT")
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (scope.row.payType==1)
                      ? (_openBlock(), _createBlock(_component_el_tag, {
                          key: 1,
                          type: "success"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("信用证")
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (scope.row.payType==2)
                      ? (_openBlock(), _createBlock(_component_el_tag, {
                          key: 2,
                          type: "info"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("OA")
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  property: "amount",
                  label: "支付金额",
                  "header-align": "center",
                  align: "right"
                }, {
                  default: _withCtx((scope) => [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.amount,false,2)), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_table_column, {
                  property: "payTime",
                  label: "支付时间"
                }),
                _createVNode(_component_el_table_column, {
                  property: "remark",
                  label: "备注"
                })
              ]),
              _: 2
            }, 1032, ["data"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_ORDER_CODE",
          label: "订单号",
          fixed: "",
          width: "110"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tag, {
              onClick: ($event: any) => (_ctx.orderDetail(scope.row)),
              type: "info",
              style: {"cursor":"pointer"},
              title: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.F_ORDER_CODE), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CODE",
          label: "PI编码",
          fixed: "",
          width: "110"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_tag, {
              onClick: ($event: any) => (_ctx.piDetail(scope.row.F_ID)),
              style: {"cursor":"pointer"},
              title: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(scope.row.F_CODE), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_SCJHH",
          label: "生产计划号",
          fixed: "",
          width: "120"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_SPLIT_NAME",
          label: "是否拆单",
          width: "80",
          filters: [
                    { text: '未操作', value: '未操作' },
                    { text: '是', value: '是' },
                    { text: '否', value: '否' },
                 ],
          "filter-method": (value,row)=>row['F_SPLIT_NAME'] === value
        }, null, 8, ["filter-method"]),
        _createVNode(_component_el_table_column, {
          prop: "F_SUM_NUM",
          label: "总数量",
          width: "60"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_PCSL",
          label: "入库数量",
          width: "60"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CHILD_ORDER_NUM",
          label: "小单数量",
          width: "60"
        }, {
          default: _withCtx((scope) => [
            (scope.row.F_SPLIT_FLAG!=0)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.row.F_CHILD_ORDER_NUM), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_FPSL",
          label: "已分配数量",
          width: "80"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_CUSTOMER_NAME",
          label: "客户名称"
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_COUNTRY_ID",
          label: "客户国家"
        }),
        _createVNode(_component_el_table_column, {
          prop: "_F_JS_TYPE",
          label: "结算币种",
          width: "80",
          filters: [
                    { text: '人民币', value: '人民币' },
                    { text: '美元', value: '美元' },
                 ],
          "filter-method": (value,row)=>row['_F_JS_TYPE'] === value
        }, null, 8, ["filter-method"]),
        _createVNode(_component_el_table_column, {
          prop: "F_TOTAL_AMOUNT",
          label: "总金额",
          width: "120",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_TOTAL_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_PAY_AMOUNT",
          label: "已付金额",
          width: "120",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_PAY_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_ADD_USER",
          label: "业务员",
          width: "100"
        }),
        _createVNode(_component_el_table_column, {
          fixed: "right",
          label: "当前进度",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createVNode(_component_el_button, {
              link: "",
              type: "primary",
              size: "small",
              onClick: ($event: any) => (_ctx.flowStepHandler(scope.row))
            }, {
              default: _withCtx(() => [
                _createTextVNode("查看")
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo", "gridLoaded", "onScjhhHandler", "onSplitOrderHandler"]),
    _createVNode(_component_el_dialog, {
      title: "设置生产计划号",
      modelValue: _ctx.otherParams.scjhh.visible,
      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.otherParams.scjhh.visible) = $event)),
      "append-to-body": true,
      draggable: "",
      "close-on-click-modal": false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          class: "iconfont icon-shanchu",
          onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.otherParams.scjhh.visible=false))
        }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          class: "iconfont icon-queding",
          onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.utilInst.doScjhh()))
        }, {
          default: _withCtx(() => [
            _createTextVNode("确定")
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_el_row, { class: "myRow" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 5,
                class: "myColTitle"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("生产计划号：")
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, { span: 19 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    onInput: _cache[10] || (_cache[10] = e => _ctx.otherParams.scjhh.scjhh = _ctx.valid(e)),
                    placeholder: "请输入生产计划号",
                    modelValue: _ctx.otherParams.scjhh.scjhh,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.otherParams.scjhh.scjhh) = $event)),
                    maxlength: "20",
                    clearable: ""
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}