import _ from "lodash";
import UtilPub from "@/utils/UtilPub";
import Tools from "@/utils/Tools";
export default class FinanceUtils{
    //选择订单弹出框确认之后，往明细表格追加选择的订单
    public static appendSelOrder(selectRows:any,grid:any):void{
        let existRows=grid.getTbInst().tbData;
        let bakData = _.cloneDeep(existRows);
        selectRows.forEach((row:any)=>{
            if(FinanceUtils.checkExist(bakData,row.F_ORDER_CODE)==-1) {
                row.F_PI_ID=row.F_ID;
                delete row.F_STATUS;//OA支付明细有自己的status，所以必须删除，否则传到后台保存的是订单的status
                delete row.F_ID;//删除F_ID，否则addRow的时候辉往更新数组里面添加
                delete row.F_REMARK;//tt信保回款得时候，弹出框选择oa支付的订单中有备注，但是确认之后，需要输入自己的备注，所以删除选中行的备注
                /**
                 * 这里出了一个问题，找了好久才找到这里，问题如下:
                 * 从弹出框选择第n跳记录添加到当前明细表格中，顺序号加入是第1行，问题来了，在当前明细表中，rowIndex本来该为0，结果rowIndex却是n，
                 * 在editTableUtil的77行点击行获取rowindex的时候就会报错，所以就必须把rowIndex删除，删除了之后，rowindex会在
                 * :row-class-name那里添加上，可是遇到一个很怪的问题，我即便这样做了，但是rowindex还是以前的，所以我才用下发深复制一份，这样rowindex就改变了
                 * */
                delete row.rowIndex;
                let tempRow = _.cloneDeep(row);
                grid.addRow(tempRow);
            }
        })
    }
    //往明细添加订单的时候，检查明细表格是否已经存在该订单了
    public static checkExist(existRows:any,addOrder:string):number{
        return existRows.findIndex((item:any)=>item.F_ORDER_CODE==addOrder);
    }

    //保存之前，验证是否选择订单以及订单是否都指定了付款方式
    public static verifySaveData(data:any,proxy:any):boolean{
        //验证明细表格数据是否合法
        if(data.length==0){
            proxy.$message('请选择订单');
            return false;
        }
        let result=data.findIndex((row:any,index:number)=>{
            if(!row.F_AMOUNT){
                Tools.warning({message:'第'+(index+1)+'行本次付款金额不能为空'});
                return true;
            }
            return false;
        })
        return result==-1;
    }
    //计算明细表格中本次付款金额总和
    public static calTotalPayAmount(data:any):any{
        let result:number=0;
        data.map((row:any,index:number)=>{
            if(row.F_AMOUNT)result+=row.F_AMOUNT;
        })
        return result;
    }
}