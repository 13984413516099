import {reactive, toRefs, computed,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import TtPayCardUtil,{ITtPayCardDataObj} from './ttPayCardUtil';
import FinanceUtils from "@/views/project/order/finance/financeUtils";
import FormalOrderList from "@/views/project/order/order/formalOrder/FormalOrderList.vue";
import PayDetail from "@/views/project/order/finance/common/payDetail/PayDetail.vue"
export default defineComponent({
    name:'TtPayCard',
    title:'TT支付',
    modelType:'card',
    fullscreen: true,
    components:{
        PayDetail
    },
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:ITtPayCardDataObj=reactive<ITtPayCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    detail:{},
                },
                details:['detail'],
                modelPath: utils.OrderProviderApi.buildUrl('/ttPay')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                customerAmountId: [utils.UtilPub.commonValidRule(proxy.$t('ttPay.form.customerAmountId_rule'))]
            },
            otherParams:{
                cardForm:'',
                customerAmountData:[],//客户
                childParams:{
                    cardFrom:'ttPay',
                    gridTitle:'TT支付明细',
                    urlPre:'ttPay'
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new TtPayCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'customerAmountId'==params.comboId){
                    return dataObj.otherParams.customerAmountData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:any,selectId:string)=>{
            if('customerAmountId'==selectId){
                dataObj.refMap.get('detail').clearDetailData();
                if(newValue){
                    dataObj.form.customerId=newValue.item.customerId;
                    dataObj.form.bz=newValue.item.bz;
                }else{
                    dataObj.form.customerId='';
                    dataObj.form.bz='';
                }
            }
            if('type'==selectId)dataObj.refMap.get('detail').clearDetailData();
        }


        //选择订单按钮事件
        const chooseOrder=async ()=>{
            if(!dataObj.form.customerAmountId){
                proxy.$message('请先选择客户');
                return;
            }
            await utils.UtilPub.openDialog({customerId:dataObj.form.customerId,bz:dataObj.form.bz,
                proxy:proxy,modelComp:FormalOrderList,ownerComp:proxy,
                from:'ttPay',overflow:'hidden',title: '选择订单',showFooterBtn:true,fullscreen:true})
        }
        //选择订单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            FinanceUtils.appendSelOrder(selectRows,dataObj.refMap.get('detail'));
            return true;
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            if('/load'==addOrLoad){
                await nextTick(()=>{
                    dataObj.form.customerAmountId=dataObj.otherParams.customerAmountData.find((item:any)=>item.value==dataObj.form.customerAmountId);
                    if(dataObj.form.customerAmountId){
                        dataObj.form.customerId=dataObj.form.customerAmountId.item.customerId;
                        dataObj.form.bz=dataObj.form.customerAmountId.item.bz;
                    }
                })
            }
            if(res.data.flag==1){//已提交，页面禁用
                dataObj.disabled=true;
                dataObj.refMap.get('detail').setReadOnly(true);
            }
            //记录从哪里进入信用证卡片
            dataObj.otherParams.cardForm=engineInst.engineParams.cardFrom;
        }
        //传入参数到后台
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom};
        }

        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            //验证明细表格数据是否合法
            let oaApplyDetailData=dataObj.refMap.get('detail').getDetailData();
            let result=FinanceUtils.verifySaveData(oaApplyDetailData,proxy);
            if(result)dataObj.form.customerAmountId=dataObj.form.customerAmountId.value;
            return result;
        }
        //保存之后
        const afterSaveHandler=async (res:any)=>{
            //如果保存失败，需要把customerAmountId给找出来，否则会是一串数字，因为在保存之前已被改变
            if(!res.result)dataObj.form.customerAmountId=dataObj.otherParams.customerAmountData.find((item:any)=>item.value==dataObj.form.customerAmountId);
            return true;
        }

        //提交
        const submitHandler=async ()=>{
            await dataObj.utilInst.doSubmit();
        }

        return{
            ...toRefs(dataObj),proxy,comboSelect,selectOnChange,beforeOpen,beforeSaveHandler,chooseOrder,sureHandler,
            setParam,afterSaveHandler,submitHandler
        }
    }
});