import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6eb63b87"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "formalOrderDetail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplitOrder = _resolveComponent("SplitOrder")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_DzzlCard = _resolveComponent("DzzlCard")!
  const _component_el_tabs = _resolveComponent("el-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_tabs, {
      modelValue: _ctx.otherParams.activeName,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.otherParams.activeName) = $event)),
      class: "demo-tabs"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: "订单详情",
          name: "first"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SplitOrder, {
              showFlag: "",
              orderId: _ctx.otherParams.orderId,
              childOrderCode: _ctx.otherParams.childOrderCode
            }, null, 8, ["orderId", "childOrderCode"])
          ]),
          _: 1
        }),
        (_ctx.otherParams.dzzl.showDzzl)
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: "单证资料",
              name: "dzzl"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DzzlCard, {
                  myParams: _ctx.otherParams.dzzl
                }, null, 8, ["myParams"])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}